/* You can add global styles to this file, and also import other style files */

//---------------------------------------------------------------------------
// Material Design Icons (https://materialdesignicons.com/)
//---------------------------------------------------------------------------
// $mdi-font-path: "../node_modules/@mdi/font/fonts";
// // @import "node_modules/@mdi/font/scss/materialdesignicons.scss";
// @import "../node_modules/@mdi/font/scss/materialdesignicons.scss";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "theme/variables.scss";

// **estilos globales
.bg-custom-lilac {
  background-color: #ce98fe;
}
.bg-custom-pink {
  background-color: #f5539b;
}
.bg-custom-light-pink {
  background-color: #E57A9B;
}
.bg-custom-green {
  background-color: #00e396;
}
.bg-custom-red {
  background-color: #ff4242;
}
.bg-custom-yellow {
  background-color: #ffb700;
}
.bg-custom-skyblue {
  background-color: #7ccde9;
}
.bg-custom-gray {
  background-color: #A9B2B9;
}

.bg-green-focused {
  background-color: #039855;
}

.basic-level-chip {
  background-color: #00e396;
}
.middle-level-chip {
  background-color: #ffb700;
}
.advanced-level-chip {
  background-color: #ff4242;
}
.competency-chip {
  background-color: #1d89af;
}
.hability-chip {
  background-color: #9b98fe;
}

.border-custom-green {
  border-color: #00e396;
}
.border-custom-red {
  border-color: #ff4242;
}
.border-custom-yellow {
  border-color: #ffb700;
}
.border-custom-skyblue {
  border-color: #7ccde9;
}
.border-custom-lilac {
  border-color: #CE98FE;
}
.custom-skyblue {
  color: #7ccde9;
}
.color-custom-green {
  color: #00e396;
}
.color-custom-red {
  color: #ff4242;
}
.color-custom-yellow {
  color: #ffb700;
}

.text-xxl {
  font-size: 1.5rem;
}
.nowrap {
  text-wrap: nowrap;
}
.hidden { display: none;}

.reset-p-ion-toolbar {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

* { font-family: "Nunito" !important;}

html {
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  input[type="text"]:focus,
  textarea:focus,
  button:focus {
    outline: none !important;
  }

  @media (min-width: 760px) {
    ::-webkit-scrollbar {
      width: 16px; /* Tamaño del scroll en vertical */
      height: 16px; /* Tamaño del scroll en horizon tal */
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 25px;
      border: 4px solid #f1f1f1;
    }

    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
    }

    /* Cambiamos el fondo cuando esté en active */
    ::-webkit-scrollbar-thumb:active {
      background-color: #999999;
    }

    /* Ponemos un color de fondo y redondeamos las esquinas del track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
  // firefox
  .side-bar-custom {
    scrollbar-color: auto var(--primary);
    grid-template-rows: 1fr auto;
    .nav-modules-btn{
      grid-template-columns: auto 1fr auto;
    }
  }
  //demás browsers
  .side-bar-custom::-webkit-scrollbar-track {
    background-color: var(--primary);
    border-color: var(--primary);
  }
  .side-bar-custom::-webkit-scrollbar-thumb {
    border-color: var(--primary);
    opacity: 0.8;
  }
}

.default-toolbar-style{
  --ion-toolbar-background: #fcfcfc;
}

.sc-ion-searchbar-ios-h{
  min-height: auto;
}
.content,
.content-desempenio, .content-equal-lg-padding {
  width: 100%;
  max-width: 1408px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.8rem 1rem 3rem 1rem;
  // @media (min-width: 768px) {
  // 	// padding-left: 5%;
  // 	// padding-right: 5%;
  // }
}

.content {
  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 4rem;
  }
  @media (min-width: 1280px) {
    padding: 1.7rem 2.5rem 4rem;
  }
  @media (min-width: 1440px) {
    padding: 2rem 2.5rem 4rem;
  }
  @media (min-width: 1600px) {
    padding: 2.3rem 0 5rem; //40px 80px
  }
}

.content-induction {
  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 5rem; //24px  80px
  }
  @media (min-width: 1280px) {
    padding: 2.5rem 2.5rem 5rem; //40px 80px
  }
  @media (min-width: 1600px) {
    padding: 2.5rem 0 5rem; //40px 80px
  }
}

.content-desempenio {
  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 5rem; //24px  80px
  }
  @media (min-width: 1280px) {
    padding: 1.5rem 2.5rem 5rem; //40px 80px
  }
  @media (min-width: 1600px) {
    padding: 1.5rem 0 5rem; //40px 80px
  }
}

.content-desktop-version {
  @media (min-width: 1024px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1408px;
    padding: 1.5rem 1.5rem 5rem; //24px  80px
  }
  @media (min-width: 1280px) {
    padding: 2.5rem 2.5rem 5rem; //40px 80px
  }
  @media (min-width: 1600px) {
    padding: 2.5rem 0 5rem; //40px 80px
  }
}

.content-induction{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0.8rem 1rem 3rem 1rem;
  @media (min-width: 1024px) {
    // max-width: 1850px;
    max-width: 90%;
  }
}

.content-equal-lg-padding{
  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 1.5rem;
  }
}

.force-reset-padding-mobile{
  @media (max-width: 1024px) {
    padding: 0 !important;
  }
}

.custom-w-btn-140{
  min-width: 140px;
}
.force-normal-weight{
  font-weight: normal !important;
}

.bg-custom-image{
  background-image: url("/assets/img/trama-white2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.full-shadow-15{
  -webkit-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.2);
}

// clases propias de texto enriquecido innerhtml
.ql-align-center{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// ****** estilos de titulos de páginas *****
.mobile-toolbar-container {
  padding: 1rem 1rem 0 1rem;
  ion-toolbar{
    --background: #fff;
  }
  // --background: #fcfcfc;
}

.title {
  font-size: 1.25rem; //20px
  line-height: 1.25rem;
  color: var(--text-color);
  font-weight: 700;
  @media (min-width: 1024px) {
    font-size: 1.5rem; //24px
    line-height: 1.5rem;
  }
  @media (min-width: 1440px) {
    font-size: 1.625rem; //26px
    line-height: 1.625rem;
  }
}

.title-icon {
  margin-right: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-size: 1.25rem;
  @media (min-width: 1024px) {
    margin-right: 1rem; /* 32px */
    line-height: 1.5rem; //24px
    font-size: 1.5rem;
  }
  // @media (min-width: 1440px) {
  //   font-size: 1.625rem; //26px
  //   line-height: 1.625rem;
  // }
}

.title-padding {
  padding-bottom: 0.875rem; //14px 0px
  @media (min-width: 1024px) {
    padding-bottom: 0.5rem; //16px
  }
  @media (min-width: 1280px) {
    padding-bottom: 0.75rem; //16px
  }
  @media (min-width: 1440px) {
    padding-bottom: 1rem; //16px
  }

  // @media (min-width: 1600px) {
  // 	// padding-bottom: 1.688rem; //27px
  // 	padding-bottom: 1.5rem; //24px
  // }
}

.division-line {
  border-color: rgba(28, 39, 49, 0.36);
}

.title-page {
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 600;
  padding: 1rem 0 1rem 0;
  ion-icon {
    font-size: 20px;
    margin-right: 0.5rem;
    color: #4d4d4d;
  }
  span {
    vertical-align: text-bottom;
    width: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 2rem;
    // padding-bottom: 1rem;
    ion-icon {
      font-size: 44px;
    }
  }
}

.search-input {
  border: 1px solid red;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  &:hover {
    border: 2px solid var(--secondary);
    border-radius: 15px;
  }
  &:focus {
    border: 2px solid var(--secondary);
    border-radius: 15px;
  }
  &::-webkit-input-placeholder {
    color: red;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: red;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: red;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: red;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: red;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  opacity: 0 !important;
}

.leading-16 {
  line-height: 3.5rem;
}
.border-radius-20 {
  border-radius: 1.25rem; //20px
}
.focused-primary-btn {
  --background: var(--primary-focused) !important;
}

ion-modal.empezar-evaluacion::part(content), ion-modal.confirmar-evaluacion::part(content) {
    border-radius: 25px !important;
    background-color: transparent;
    box-shadow: none;
}
// .empezar-evaluacion {
//   .modal-wrapper {
//     border-radius: 25px !important;
//     background-color: transparent;
//     box-shadow: none;
//   }
// }
// .confirmar-evaluacion {
//   .modal-wrapper {
//     border-radius: 25px !important;
//     background-color: transparent;
//     box-shadow: none;
//   }
// }
.side-menu-mobile {
  .modal-wrapper {
    height: 100%;
    position: relative;
    margin-top: auto;
    margin-bottom: 0;
    width: 255px;
    margin-left: auto;
    margin-right: 0;
    background-color: var(--primary);
  }
  .sidemenu-mobile-icon-container {
    background-color: rgba(var(--primary), 0.8);
  }
}
.text-pct {
  color: white;
  text-shadow: 1px 0 0 black, -1px 0 0 black, 0 1px 0 black, 0 -1px 0 black,
    1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black;
}

ion-toolbar {
  .toolbar-container {
    padding: 0px !important;
  }
  &.toolbar-container {
    padding: 0px !important;
  }
}

.sc-ion-alert-ios-h {
  .alert-wrapper .alert-button-group-vertical {
    flex-direction: row;
  }
  .alert-title {
    font-size: 1.2rem;
  }
  .alert-message {
    font-size: 1rem;
  }
  .alert-button-group {
    &:last-child .alert-button {
      // border: none;
      font-weight: 500;
    }
  }
}

.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar {
  display: block;
  width: 0.3rem;
}

.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-track,
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #d1d5db;
}

.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 100vh;
}

.searchbar-cancel-button.sc-ion-searchbar-md {
  left: 10px !important;
}

.modal-checklist {
  --background: transparent;
  --box-shadow: none !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.7) !important;
  @media (min-width: 600px) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.3) !important;
  }
  .modal-wrapper {
    bottom: 0;
    position: absolute;
    @media (min-width: 600px) {
      bottom: auto;
    }
  }
}

.toast-checklist {
  --background: #3880ff;
  --border-radius: 1rem;
}

span img {
  width: 1.2rem;
}

a {
  color: rgb(83, 31, 187);
}

div,
section,
button,
ion-icon,
span,
p,
table,
thead,
tr,
th {
  animation: fadeIn 505ms 1;
  -webkit-animation: fadeIn 505ms 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.action-sheet-title.sc-ion-action-sheet-md {
  background: #edf2f7;
  border-bottom: solid 1px var(--primary);
  color: #28314f;
  font-weight: 500;
}

.action-sheet-group-cancel.sc-ion-action-sheet-md {
  background: #edf2f7;
  border-top: solid 1px var(--primary);
  display: flex;
  justify-content: right;
  .action-sheet-button.sc-ion-action-sheet-md {
    color: white;
    font-weight: 500;
    width: 6.2rem;
    background: var(--primary);
    margin: 0.5rem;
    height: 2rem;
    border-radius: 0.5rem;
    .action-sheet-button-inner.sc-ion-action-sheet-md {
      justify-content: center;
    }
  }
}
ion-modal.modal-reunion::part(content){
  background-color: transparent;
  box-shadow: none;
}
.modal-reunion {
  --width: 30rem;
}

.ionic-selectable-modal-is-multiple {
  ion-header {
    ion-toolbar {
      --background: #edf2f7;
      ion-title {
        --color: #040404;
      }
      ion-button::part(native) {
        background: var(--primary);
        border-radius: 5px;
        color: #fff;
        font-size: 0.8rem;
      }
    }
    ion-toolbar:last-child {
      border-bottom: solid 1px var(--primary);
      padding: 0 1rem;
      ion-searchbar {
        --border-radius: 0.5rem;
        --box-shadow: 0px;
        --background: #fff;
        --clear-button-color: var(--primary);
        border-radius: 0.5rem;
        border: 1px solid #b9b9b9;
        padding: 0;
      }
    }
  }
  ion-footer {
    ion-toolbar {
      border-top: solid 1px var(--primary);
      --background: #edf2f7;
      ion-button::part(native) {
        background: var(--primary);
        border-radius: 5px;
      }
    }
  }
}

.ionic-selectable-value {
  span {
    display: flex;
    .ionic-selectable-value-item:not(:last-child) {
      margin-bottom: 0;
    }
    .ionic-selectable-value-item {
      overflow: inherit;
      background-color: var(--primary);
      border-radius: 9999px;
      margin-right: 0.5rem;
      color: white;
      padding: 0 0.5rem;
    }
  }
}

.action-sheet-title.sc-ion-action-sheet-ios {
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .action-sheet-container.sc-ion-action-sheet-md,
  .action-sheet-title.sc-ion-action-sheet-ios {
    justify-content: center;
    .action-sheet-group.sc-ion-action-sheet-md:first-child {
      border-radius: 8px 8px 0 0;
    }
    .action-sheet-group.sc-ion-action-sheet-md:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}

.mr-0-i {
  margin-right: 0rem !important;
}

ion-modal {
  @media (min-width: 1280px) {
    --border-radius: 0.5rem !important;
  }
}

.custom-password-modal {
  .alert-head {
    background: #0000d2;
    padding-bottom: 16px;
    h2 {
      color: #ffffff;
      margin-top: 0px;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
    }
  }
  .alert-message {
    padding-top: 16px !important;
  }
}

.popover-historico .popover-content {
  --width: 95%;
  font-size: 14px;
  --height: 260px;
  @media (min-width: 760px) {
    --width: 320px;
    --height: 300px;
  }
  @media (min-width: 1024px) {
    --width: 360px;
  }
  .item .sc-ion-label-ios-h {
    white-space: normal;
    font-size: 15px;
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
}

// .content-categorias{
// 	.sc-ion-popover-ios-h {
// 		--width: 255px;
// 		@media (min-width: 1024px) {
// 			--width: 290px;
// 		}
// 	}
// }
.reload-alert {
  --max-width: 520px;

  border-radius: 8px;
  .alert-head {
    padding: 18px 0 0 0 !important;
  }

  .alert-message {
    align-items: center;
    color: #4d4d4d;
    font-size: 1.25rem;
    padding: 0 18px 16px !important;
  }
  .alert-button-group {
    padding: 0 20px 6px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      background: #0000d2;
      color: #ffffff;
      min-width: 220px;

      border-radius: 6.75px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      text-align: center;
      margin-bottom: 16px;
      margin-left: auto;
      margin-right: auto;
      span {
        justify-content: center;
        text-transform: none;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
.redirection-modal {
  .alert-wrapper {
    border-radius: 40px;
    padding-bottom: 12px;
    contain: none;
    overflow: visible !important;
    --max-width: 88%;
    @media only screen and (max-width: 280px) {
      --max-width: 270px;
    }
    @media only screen and (min-width: 576px) {
      --max-width: 347px;
    }
  }
  .alert-head {
    background: #0000d2;
    padding: 17px 18px 17px 18px;
    border-radius: 40px 40px 0 0;
    h2 {
      color: #ffffff;
      margin-top: 0px;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      @media only screen and (min-width: 576px) {
        font-size: 21px;
      }
    }
  }

  .alert-message {
    display: flex;
    text-align: left;
    padding: 13px 13px 24px 13px !important;
    align-items: center;
    .msg {
      color: #4d4d4d;
    }
    // img {
    // 	margin-left: 5px;
    // 	max-height: 140px;
    // 	width: auto;
    // }
    p:first-child {
      margin-bottom: 12px;
    }
    p:last-child {
      margin-top: 12px;
    }
  }
  .alert-button-group {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 8px 8px 8px;

    // .huawei-app, .apple-app, .android-app {
    // 	background-position: center;
    // 	background-repeat: no-repeat;
    // 	background-size: contain;
    // 	width: 40px;
    // 	height: 40px;
    // 	margin: 0 7px;
    // };
    // .huawei-app{
    // 	background-image: url("./assets/img/AppGallery_Badges.svg");
    // };
    // .apple-app{
    // 	background-image: url("./assets/img/appstore.png");
    // };
    // .android-app{
    // 	background-image: url("./assets/img/PlayStore_Badges.svg");
    // };

    button {
      background: #0000d2;
      color: #ffffff;

      border-radius: 6.75px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      text-align: center;
      margin-bottom: 8px;
      span {
        justify-content: center;
        text-transform: none;
        font-size: 16px;
      }
    }
    .simple-btn {
      min-width: 10rem !important;
      border-radius: 10px;
      flex-grow: 0 !important;
    }
    .close-btn {
      position: absolute !important;
      top: 4px;
      right: -5px;
      max-width: 24px !important;
      min-width: 23px !important;
      height: 24px;
      border-radius: 50%;
      z-index: 10;
      font-weight: bold !important;
      background: var(--primary);
      padding: 12px;
    }
    .alert-button.sc-ion-alert-ios {
      min-width: 40px;
      flex-grow: 0;
      margin: 0 6px;
    }
  }
}

.footer__leave-btn {
  display: none;
}

.meeting-info-container {
  z-index: 99999 !important;
}

.meeting-info-container--right-side {
  top: 45px !important;
}

.dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

.meeting-client,
.meeting-client-inner {
  height: 95vh !important;
  top: auto !important;
  bottom: 0;
}

.joyride-step__container {
  width: 240px !important;
  height: auto !important;
  // border: 0.5px solid $primary;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
  color: #1a2128;
}

.joyride-step__close,
.joyride-step__header {
  display: none !important;
}

.joyride-step__footer {
  padding: 0 8px;
}

.backdrop-target {
  border: 1.5px dashed var(--primary);
}

.joyride-button {
  text-transform: none !important;
  background-color: white !important;
  color: var(--primary) !important;
  border-radius: 22.5px;
  font-weight: 600 !important;
  font-size: 10px !important;
  padding: 6px 0 !important;
  width: 63px;
}

.joyride-step__prev-container .joyride-button:hover {
  border: 1px solid white !important;
}

.joyride-step__next-container .joyride-button {
  border: 1px solid var(--primary) !important;
}

.joyride-step__done-button .joyride-button {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  color: white !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.popover-leave {
  --backdrop-opacity: 0.8;
  --min-width: 28rem;
  --max-width: 30rem;
}

.meeting-info-container--left-side {
  display: none !important;
}

.chat-receiver-list__receiver {
  font-size: 12px !important;
  border-radius: 10.5px !important;
  padding: 2px 15px !important;
}

.chat-container__chat-list {
  height: 78vh !important;
}

.zmu-tabs__tab-bar:first-child {
  display: none;
}

.zmu-tabs__tab-bar {
  min-width: auto !important;
}

.ReactModal__Overlay--after-open {
  z-index: 99999 !important;
}

.audio-option-menu__pop-menu,
.security-option-menu__pop-menu {
  left: 0 !important;
  width: 270px !important;
}

.dropdown-menu > li > a {
  padding: 3px 35px !important;
}

ion-picker {
  --border-radius: 0.5rem 0.5rem 0rem 0rem !important;
}

.ul_alert {
  list-style: inside !important;
  text-align: left !important;
  padding: 0 1rem;
}

.alert-meeting-code {
  .alert-wrapper.sc-ion-alert-ios {
    min-width: 320px;
    max-width: 335px;
  }
  @media (min-width: 768px) {
    .alert-wrapper.sc-ion-alert-ios {
      max-width: 400px;
    }
  }
}
.start-meeting-modal {
  .alert-wrapper.sc-ion-alert-ios {
    min-width: 320px;
    max-width: 335px;
  }
  @media (min-width: 768px) {
    .alert-wrapper.sc-ion-alert-ios {
      max-width: 400px;
    }
  }
}

.card_titulo .card-content-ios {
  h1 {
    font-size: 1.3rem;
    font-weight: 700;
  }
  p {
    font-size: 1.15rem;
    padding-top: 10px;
    font-weight: 500;
  }
}
.modal-asistentes-aulas-virtuales .card-content-ios {
  padding: 5px;

  @media (min-width: 768px) {
    padding: 20px;
  }
}

/* Re-design v2 */
footer {
  .bg_footer {
    background: white url("assets/img/footer_login_movil.png") center bottom
      no-repeat;
    background-size: 94% auto;
    height: 100px;
    margin-bottom: 10px;
    background-position: center;
  }

  @media (min-width: 768px) {
    .bg_footer {
      background: white url("assets/img/footer_login_web.png") center bottom
        no-repeat;
      background-size: 70% auto;
      height: 80px;
      margin-bottom: 0;
      background-position: center;
    }
  }
}

/* vademecum */
.custom-alert-filter,
.custom-alert-Glosario {
  .alert-wrapper {
    --min-width: 85%;
    @media (min-width: 768px) {
      --max-width: 350px;
      --min-width: 330px;
    }
  }
}
ion-modal.modal-vademecum::part(content){
  box-shadow: none;
}
.modal-vademecum {
  --width: 100%;
  --height: 100vh;
}

app-scorm {
  ion-content {
    --padding-top: 40px !important;
    --background: #2e2e2e;
  }
}
/*certificados*/
.certificate-options,
content {
  .sc-ion-searchbar-ios-h {
    height: 32px;
    @media (min-width: 768px) {
      height: 40px;
    }
  }

  .ios ion-searchbar {
    height: 32px;
    @media (min-width: 768px) {
      height: 40px;
    }
  }
}

.ios ion-searchbar {
  height: 34px;
  @media (min-width: 768px) {
    height: 40px;
  }

  .searchbar-input-container {
    input {
      font-size: 15px !important;
    }
  }
}

ion-searchbar.searchbar-custom-component {
  --border-radius: 8px;
  --box-shadow: 0px;
  --background: #fff;
  --clear-button-color: #fff;
  border-radius: 8px;
  border: 1px solid #bcbcbc;
  padding: 0;

  height: 40px;
  @media (min-width: 1024px) {
    height: 44px;
  }
  &.md-header-searchbar {
    @media (min-width: 768px) {
      height: 35px;
      border-color: transparent;
    }
  }
  &.lg-header-searchbar {
    @media (min-width: 1024px) {
      height: 35px;
      border-color: transparent;
    }
  }

  .searchbar-input-container {
    input {
      font-size: 16px !important;
      padding-left: 12px !important;
    }
    button {
      left: 16px;
      right: auto;
      display: none !important;
    }
    ion-icon {
      right: 13px;
      left: auto;
    }
  }
}

.addMb {
  margin-bottom: 0;
  z-index: 999;
  @media (min-width: 769px) {
    margin-bottom: 4rem;
    z-index: 0;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
}

.custom-popover .popover-content {
  --height: auto !important;
  --max-width: 280px !important;
  padding: 0.3rem 0.5rem;
  font-size: 1.01rem;
  font-weight: 500;
  text-align: center;
}

.custom-popover-info .popover-content {
  --height: auto !important;
  --width: 19.563rem !important;
  --max-width: 19.563rem !important;
  padding: 0.5rem 0.8rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  text-align: center;
  --box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  color: #5757ea;
}
.noHover {
  pointer-events: none;
}
ion-modal.platform-modal::part(content) {
  border-radius: 25px !important;
  background-color: transparent;
  box-shadow: none;
}
// .platform-modal {
//   .modal-wrapper {
//     border-radius: 25px !important;
//     background-color: transparent;
//     box-shadow: none;
//   }
// }

.grecaptcha-badge {
  bottom: 107px !important;
  width: 245px !important;
  height: 53px !important;

  @media (min-width: 768px) {
    //bottom: 75px !important;
    bottom: 58px !important;
    width: 256px !important;
    height: 60px !important;
  }
}

.toast-tema,
.toast-error-send-signature {
  --background: #ffffff;
  --border-color: var(--primary);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  --border-radius: 9px;
  --color: black;
  --start: 1rem;
  --end: 1rem;
  z-index: 1 !important;
  height: calc(100% - 4rem);
  --max-width: 22rem;
  @media (min-width: 1024px) {
    --max-width: 40.438rem;
  }
}

ion-toast.toast-tema::part(container),
ion-toast.toast-error-send-signature::part(container) {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 15px 15px 15px;
}

ion-toast.toast-tema::part(message),
ion-toast.toast-error-send-signature::part(message) {
  font-size: 0.875rem;
  line-height: 20px;
  text-align: left;
  color: #a9b2b9;
  @media (min-width: 1024px) {
    text-align: center;
    color: #2a3649;
  }
}

ion-toast.toast-tema::part(header),
ion-toast.toast-error-send-signature::part(header) {
  display: flex;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 0.625rem;
  @media (min-width: 1024px) {
    justify-content: center;
  }
}

ion-toast.toast-tema::part(header)::before,
ion-toast.toast-error-send-signature::part(header)::before {
  width: 1.188rem;
  padding-right: 8px;
  content: url("assets/icon/alert.svg");
  @media (min-width: 1024px) {
    width: 1.313rem;
  }
}

ion-toast.toast-tema::part(button),
ion-toast.toast-error-send-signature::part(button) {
  background: var(--primary);
  width: auto;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  text-transform: none;
  @media (min-width: 1024px) {
    margin-right: 0.75rem;
  }
}

.text-pctj {
  color: white;
  text-shadow: none;
  top: 2px;
}

.titleC {
  font-size: 20px;
  color: #1a2128;
  font-weight: 800;
  padding: 1rem 0 0.875rem 0;
  ion-icon {
    font-size: 1.875rem;
    margin-right: 0.5rem;
    color: #1a2128;
  }
  span {
    vertical-align: text-bottom;
    width: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 2rem;
    ion-icon {
      font-size: 36px;
    }
  }
}

// .content-curso {
// 	width: 100%;
// 	margin-left: auto;
// 	margin-right: auto;
// 	max-width: 96rem;
// }

/*cambiar contraseña*/
.toast-updated-password {
  --background: #fff;
  --border-color: var(--primary);
  --border-width: 2px;
  --border-style: solid;
  --border-radius: 5px;
  color: var(--primary);
  font-weight: 600;
  font-size: 1rem;
  @media (min-width: 780px) {
    --max-width: 380px;
  }
}
.toast-password-lg {
  @media (min-width: 1300px) {
    transform: translateX(calc(100vw - 65vw)) !important;
  }
}

ion-toast.toast-updated-password::part(button) {
  height: auto;
  width: 30px;
  font-size: 1.2rem;
  padding: 0;
  margin-left: 15px;
  @media (min-width: 1024px) {
    margin-left: 35px;
  }
}

.item-input ion-label {
  pointer-events: all !important;
}
.item-cover {
  display: none;
}

.custom-tooltip-password .popover-content {
  --width: 16.5rem !important;
  padding: 0.5rem 0.5rem;
  font-size: 1.01rem;
  font-weight: 500;
  text-align: center;
  border: 2px solid var(--primary);
}
.custom-tooltip-password .popover-arrow::after {
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary) transparent transparent var(--primary);
  z-index: 999999999;
}

// ion-modal.basic-modal {
// 	.modal-wrapper {
// 		background-color: transparent !important;
// 		box-shadow: none;
// 	}

// }
ion-modal.basic-modal::part(content), ion-modal.basic-modal-desempeno::part(content) {
  background-color: transparent !important;
  box-shadow: none;
}
ion-modal.basic-modal {
  @media (min-width: 1024px) {
    --height: 700px;
  }
}
ion-modal.calendar-multiple-date-modal {
  --height: fit-content;
  --width: fit-content;
}
ion-modal.checklist-ko-reason-modal::part(content) {
  @media (min-width: 768px) {
    --height: 700px !important;
  }
  @media (min-width: 1024px) {
    --height: 700px;
  }
  @media (min-width: 1440px) {
    --height: 720px !important;
  }
}
ion-modal.ch-activities-details-modal::part(content) {
  --height: 600px;
  @media (min-width: 768px) {
    --height: 650px !important;
    --width: 680px;
  }
  @media (min-width: 1024px) {
    --height: 650px;
    --width: 880px;
  }
  @media (min-width: 1440px) {
    --height: 650px;
    --width: 970px;
  }
  @media (min-width: 1500px) {
    --width: 1050px;
  }
  @media (min-width: 1800px) {
    --width: 1200px;
  }
}

/*modulo de desempeño*/

.desempeno-color-primary-text {
  color: var(--primary);
}
.desempeno-secondary-text {
  color: var(--gray-secondary-color);
}
.bg-ion-btn-secondary {
  --background: var(--primary-focused);
}

.custom-md-popover {
  --width: 10rem !important;
}
.notifications-box {
  .popover-content {
    border-radius: 10px !important;
  }
  //--width: 10rem !important;
  .item .sc-ion-label-md-h {
    white-space: normal;
  }
  .sc-ion-label-md-s p {
    font-size: 0.75rem;
  }
  .sc-ion-label-md-s > p {
    color: #a9b2b9;
  }
}

/*-----gestion de objetivos-----*/
.toast-checkmark-desempeno,
.toast-warning-desempeno {
  --background: #ffffff;
  --border-color: var(--primary-focused);
  --border-width: 2px;
  --border-style: solid;
  --border-radius: 9px;
  --box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.25);
  --color: black;
  // --start: 1rem;
  // --end: 1rem;
  //z-index: 5!important;
  // height: calc(100% - 4rem);
}

ion-toast.toast-checkmark-desempeno::part(container),
ion-toast.toast-warning-desempeno::part(container) {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // font-size: 2rem;
}

ion-toast.toast-checkmark-desempeno::part(message),
ion-toast.toast-warning-desempeno::part(message) {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
}

ion-toast.toast-checkmark-desempeno::part(button),
ion-toast.toast-warning-desempeno::part(button) {
  color: #000000;
  margin-top: 13px;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

/**************/

.toast-saved-goal {
  --background: #fff;
  --border-color: var(--primary);
  --border-width: 2px;
  --border-style: solid;
  --border-radius: 10px;
  color: var(--primary);
  //font-weight: 600;
  font-size: 1rem;
}

ion-toast.toast-saved-goal::part(button) {
  height: auto;
  width: 30px;
  font-size: 1.2rem; // this sets actual icon size
  padding: 0;
  margin-left: 10px;
  @media (min-width: 1024px) {
    margin-left: 35px;
  }
}

/*modal form - crear objetivo*/
ion-modal.modal-create-goal-form {
  --height: 82vh;
  --border-radius: 15px;
  --width: 94vw;
  @media (min-width: 640px) {
    --width: 85vw;
  }
  @media (min-width: 768px) {
    --width: 60vw;
  }
  @media (min-width: 1200px) {
    --width: 50vw;
  }
  @media (min-width: 1400px) {
    --width: 40vw;
  }
}

ion-modal.edit-modal {
  --height: 82vh;
  --border-radius: 15px;
  --width: 94vw;
  @media (min-width: 640px) {
    --width: 85vw;
  }
  @media (min-width: 768px) {
    --width: 60vw;
  }
  @media (min-width: 1200px) {
    --width: 50vw;
  }
  @media (min-width: 1400px) {
    --width: 40vw;
  }
}

/*modal - calendar*/
.modal-calendar {
  .modal-wrapper {
    background-color: transparent !important;
    box-shadow: none;
  }

  .table th {
    text-transform: capitalize;
  }
  .selected-date-cursalab,
  .selected-date-IR {
    color: #ffffff;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 8px;
    top: -8px;
    left: -8px;
    box-sizing: content-box;
  }
  .selected-date-cursalab {
    background-color: var(--primary) !important;
  }
  .selected-date-IR {
    background-color: var(--primary) !important;
  }

  .remove-padding {
    padding: 0 !important;
  }
}
.select-year-calendar .popover-content {
  --height: 17rem;
  --max-height: 17rem;
}

/*custom-select-mobile*/
.select-mobile-modal-standar .modal-wrapper {
  --height: 35vh;
  margin-top: 65vh !important;
  border-radius: 0.625rem 0.625rem 0 0;
}
.select-mobile-modal-small .modal-wrapper {
  --height: 28vh;
  margin-top: 70vh !important;
  border-radius: 0.625rem 0.625rem 0 0;
}
.center-select-items {
  ion-item::part(native) {
    text-align: center;
  }
}
//select desktop
.dropdown-select {
  input.native-input {
    cursor: pointer !important;
  }
}

.modal-calendar-range {
  .month-title {
    text-transform: capitalize;
  }
  ion-header ion-toolbar {
    --background: var(--primary) !important;
    ion-button {
      --color: #fff !important;
      font-weight: 600;
    }
    .week-title {
      li {
        color: #fff !important;
      }
    }
  }
  ion-content {
    .light .days .on-selected p,
    .light .endSelection button.days-btn p,
    .light .between button.days-btn p {
      color: #fff !important;
    }
    .light .days .today p {
      color: var(--primary) !important;
    }
  }
}
//iniciativas
.modal-iniciativas {
  .modal-wrapper {
    background-color: transparent !important;
  }
}
.modal-edit-initiative-mobile {
  --backdrop-opacity: 0.3;
  .modal-wrapper {
    background-color: transparent !important;
  }
}
.modal-edit-initiative-mobile .modal-wrapper {
  --height: 7.4rem !important;
  margin-top: calc(100vh - 7.5rem) !important;
}

.modal-edit-initiative {
  --backdrop-opacity: 0.3;
  @media (min-width: 640px) {
    --width: 85vw;
  }
  @media (min-width: 768px) {
    --width: 60vw;
  }
  @media (min-width: 1200px) {
    --width: 50vw;
  }
  @media (min-width: 1400px) {
    --width: 40vw;
  }
  .modal-wrapper {
    background-color: transparent !important;
  }
}

.modal-edit-initiative .modal-wrapper {
  --height: 7.4rem !important;
  margin-top: calc(100vh - 7.5rem) !important;
  //border-radius: .625rem .625rem 0 0;
  @media (min-width: 640px) {
    margin-top: calc(82vh - 7.5rem) !important;
  }
}

/*unificacion style*/
.top-left-5 {
  top: 1.25rem;
  left: 1.25rem;
}

.top-right-5 {
  top: 1.25rem;
  right: 1.25rem;
}

.bottom-left-5 {
  bottom: 1.25rem;
  left: 1.25rem;
}

.bottom-right-5 {
  bottom: 1.25rem;
  right: 1.25rem;
}

.social-media-popup .popover-content {
  --max-width: 10rem !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0.25rem;
}

.content-checklist {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1408px;
  padding: 0.5rem 5% 5% 5%;
  @media (min-width: 768px) {
    // padding-left: 5%;
    // padding-right: 5%;
  }
  @media (min-width: 1024px) {
    // margin-left: 2.5rem;
    // margin-right: 15rem;
    // width: 85%;
  }
  @media (min-width: 1280px) {
    padding: 0 5% 5% 5%;
  }
  @media (min-width: 1536px) {
    padding: 0 30px 20px 30px;
    margin-right: auto;
  }
}

ion-searchbar.search-list,
ion-searchbar.search-certificate,
ion-searchbar.search-document,
ion-searchbar.searchbar-standar-lg {
  .searchbar-input-container {
    input {
      font-size: 0.875rem !important;
      padding: 0.25rem 3rem 0.25rem 1.5rem;
      color: #3a3a3a;
      @media (min-width: 1024px) {
        font-size: 1.07rem !important;
      }
    }

    button {
      left: 16px;
      right: auto;
      display: none !important;
    }
    ion-icon {
      right: 13px;
      left: auto;
    }
  }
}

/* Scoped components require higher specificity to customize */
ion-searchbar.custom-search-bar-comp{
  padding: 0;
  border: 1px solid #bcbcbc;
  border-radius: 18px;
  min-height: 36px;
  --background: #fff;
  --box-shadow: 0px;
  --color: var(--text-color);
  --placeholder-color: #A9B2B9;
  --icon-color: var(--text-color);
  --border-radius: 18px;
  .searchbar-input-container {
    input {
      padding-inline-start: 12px !important;
      padding-inline-end: 12px !important;
      @media (min-width: 1024px) {
        padding-inline-end: 0px !important;
      }
    }

    ion-icon {
      right: 12px;
      left: auto;
      @media (min-width: 1024px) {
        right: 2px;
        margin-right: 0
      }
    }
  }

  @media (min-width: 1024px) {
    border-color: transparent;
  }
}

.toast-checklist {
  --background: #ffffff;
  --border-color: var(--primary);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  --border-radius: 9px;
  --color: black;
  --start: 1rem;
  --end: 1rem;
  --max-width: 22rem;
  margin-top: 2rem;
  @media (min-width: 1024px) {
    --max-width: 46.4rem;
  }
}

ion-toast.toast-checklist::part(container) {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 1.62rem 0 1.62rem;
  .toast-content {
    flex-direction: row;
  }
}

ion-toast.toast-checklist::part(container)::before {
  @media (min-width: 1024px) {
    width: 2rem;
    height: 2.4rem;
    margin: auto 1.5rem;
    content: url("assets/icon/alert-check.svg");
  }
}

ion-toast.toast-checklist::part(message) {
  font-size: 0.875rem;
  line-height: 20px;
  text-align: left;
  color: #a9b2b9;
  @media (min-width: 1024px) {
    text-align: left;
    color: #2a3649;
  }
}

ion-toast.toast-checklist::part(header) {
  display: flex;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 0.625rem;
}

ion-toast.toast-checklist::part(header)::before {
  width: 1.188rem;
  padding-right: 8px;
  content: url("assets/icon/alert-check.svg");
  @media (min-width: 1024px) {
    width: 0;
    padding-right: 0;
    content: "";
  }
}

ion-toast.toast-checklist::part(button) {
  background: var(--primary);
  width: auto;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  text-transform: none;
  @media (min-width: 1024px) {
    margin-right: 0.75rem;
  }
}

.toast-checklist-disabled {
  --background: #ffffff;
  --border-color: var(--primary);
  --border-width: 1px;
  --box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  --border-radius: 9px;
  --color: black;
  --start: 1rem;
  --end: 1rem;
  --max-width: 22rem;
}

ion-toast.toast-checklist-disabled::part(container) {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 15px 15px 15px;
  .toast-content {
    flex-direction: row;
  }
}

ion-toast.toast-checklist-disabled::part(message) {
  font-size: 0.875rem;
  line-height: 20px;
  text-align: center;
  @media (min-width: 1024px) {
    text-align: center;
  }
}

ion-toast.toast-checklist-disabled::part(header) {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 18px;
  margin-bottom: 1rem;
  @media (min-width: 1024px) {
    justify-content: center;
  }
}

ion-toast.toast-checklist-disabled::part(header)::before {
  width: 2.125rem;
  margin-bottom: 1rem;
  content: url("assets/icon/locked.svg");
  @media (min-width: 1024px) {
    width: 1.313rem;
  }
}

ion-toast.toast-checklist-disabled::part(button) {
  background: var(--primary);
  width: auto;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  text-transform: none;
  @media (min-width: 1024px) {
    margin-right: 0.75rem;
  }
}

.toast-checklist-confirmar {
  --background: #ffffff;
  --max-width: 18.75rem;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.7);
  @media (min-width: 1024px) {
    --max-width: 18.75rem;
  }
  .alert-wrapper {
    border: 1px solid var(--quaternary);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 9px;
    text-align: center;

    .alert-head {
      text-align: center;
      h2 {
        margin: 0;
        font-size: 1rem;
        display: inline-flex;
        font-weight: 700;
      }
    }
    .alert-message {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #a9b2b9;
    }

    .alert-button-group {
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 12px;
      justify-content: center;
      button {
        // margin-left: 1rem;
        // margin-right: 1rem;
        max-width: 6.5rem;
        span {
          text-transform: none;
        }
      }
      .toast-btn-cancel {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #a9b2b9;
        border: none;
        height: 37px;
      }
      .toast-btn-confirm {
        background: var(--primary);
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
        height: 37px;
      }
    }
  }
}

ion-alert.toast-checklist-confirmar::part(container) {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 15px 15px 15px;
}

ion-alert.toast-checklist-confirmar::part(message) {
  font-size: 0.875rem;
  line-height: 20px;
  text-align: center;
  color: #a9b2b9;
  @media (min-width: 1024px) {
    text-align: center;
    color: #2a3649;
  }
}

ion-alert.toast-checklist-confirmar::part(header) {
  display: flex;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 0.625rem;
  justify-content: center;
  @media (min-width: 1024px) {
    justify-content: center;
  }
}

ion-alert.toast-checklist-confirmar::part(header)::before {
  width: 1.188rem;
  padding-right: 8px;
  content: url("assets/icon/alert.svg");
  @media (min-width: 1024px) {
    width: 1.313rem;
  }
}

ion-alert.toast-checklist-confirmar::part(button) {
  background: var(--primary);
  width: auto;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  text-transform: none;
  @media (min-width: 1024px) {
    margin-right: 0.75rem;
  }
}

.toast-checklist-congratulations {
  --background: #ffffff;
  --border-color: var(--quaternary);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  --border-radius: 9px;
  --color: black;
  --start: 1rem;
  --end: 1rem;
  z-index: 1 !important;
  height: calc(100% - 4rem);
  --max-width: 20.5rem;
}

ion-toast.toast-checklist-congratulations::part(container) {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 2rem;
  padding: 0;
}

ion-toast.toast-checklist-congratulations::part(message) {
  font-size: 0.875rem;
  line-height: 20px;
  text-align: center;
  color: #a9b2b9;
}

ion-toast.toast-checklist-congratulations::part(header) {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 0.625rem;
  justify-content: center;
  align-items: center;
}

ion-toast.toast-checklist-congratulations::part(header)::before {
  width: 3.563rem;
  padding-bottom: 0.625rem;
  content: url("assets/icon/star.svg");
}

.select-status {
  --min-height: 1.875rem;
  --border-style: none;
  ion-radio {
    margin: 0;
  }
}

/*unificacion style*/
.top-left-5 {
  top: 1.25rem;
  left: 1.25rem;
}

.top-right-5 {
  top: 1.25rem;
  right: 1.25rem;
}

.bottom-left-5 {
  bottom: 1.25rem;
  left: 1.25rem;
}

.bottom-right-5 {
  bottom: 1.25rem;
  right: 1.25rem;
}
.evaluation-icon {
  width: 0.875rem;
  @media (min-width: 768px) {
    width: 1rem;
  }
}

.modulo-reconocimiento-btn {
  font-size: 0.875rem !important;
}
.state_green {
  .alert-radio-label.sc-ion-alert-ios {
    color: #00e396 !important;
  }
}
.state_yellow {
  .alert-radio-label.sc-ion-alert-ios {
    color: #ffb700 !important;
  }
}
.state_red {
  .alert-radio-label.sc-ion-alert-ios {
    color: #ff4560 !important;
  }
}
.state_gray {
  .alert-radio-label.sc-ion-alert-ios {
    color: #a9b2b9 !important;
  }
}

.innerHTML {
  ol {
    list-style: auto;
    list-style-position: inside;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
}

.reconocimiento-card-btn {
  font-size: 0.875rem !important;
  --background: var(--primary-focused) !important;
}

.modal-select-mobile-reconocimiento {
  --background: transparent;
  --box-shadow: none !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5) !important;
  @media (min-width: 600px) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.3) !important;
  }
  .modal-wrapper {
    bottom: 0;
    position: absolute;
    @media (min-width: 600px) {
      bottom: auto;
    }
  }
}

.dropdown-select,
.custom-time-picker {
  input.native-input {
    cursor: pointer !important;
  }
}

.toast-basic-conformidad {
  --background: #fff;
  --border-color: #039855;
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 0.625rem;
  --start: 1rem;
  --end: 1rem;
  color: #1a2128;
  font-size: 0.875rem;
  display: flex;
  flex: initial;
  margin-top: 3rem;
  @media (min-width: 780px) {
    --max-width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

ion-toast.toast-basic-conformidad::part(container) {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

ion-toast.toast-basic-conformidad::part(message) {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
  @media (min-width: 780px) {
    flex-direction: row;
    gap: 0.75rem;
  }
}

ion-toast.toast-basic-conformidad::part(button) {
  display: flex;
  justify-content: center;
  color: #039855;
  margin: 0;
}

.dot-flashing {
  position: relative;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--primary);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -0.5rem;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--primary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 0.5rem;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--primary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--primary);
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.w-max {
  width: max-content;
}

ion-modal.reload-modal {
  --width: 100vw;
  --height: 100vh;
  --background: transparent;
  --box-shadow: none !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.2) !important;
}
.custom-reload-modal-width {
  @media (min-width: 1024px) {
    width: 27rem !important;
  }
}

.exam-timesup-modal {
  --box-shadow: none !important;
  --background: #e0dfdf00 !important;
  backdrop-filter: blur(4px);
  ion-backdrop {
    background: rgb(144 145 147 / 0.5);
  }
}

.warning-availability-detail,
.warning-circle-chip {
  background-color: #fcedc7;
}
.warning-icon,
.warning-circle-chip {
  color: #ffcd00;
}

.evaluation-resume-btns {
  --padding-top:14px !important;
  --padding-bottom:14px !important;
}
.evaluation-resume-btns, .outline-evaluation-resume-btns {
  --padding-start: 2rem ;
  --padding-end: 2rem;
  max-width: 200px;
  width: 200px;
  @media (min-width: 1024px) {
    width: 280px;
    max-width: 280px;
  }
}
.outline-evaluation-resume-btns{
  --padding-top: 13px !important;
  --padding-bottom: 13px !important;
}

.pending-media {
  --backdrop-opacity: 0;
  @media (min-width: 576px) {
    --min-width: 470px;
  }
  .alert-wrapper {
    border-radius: 9px;
    border: 1px solid #ffcd00;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .alert-head {
    padding: 0;
  }

  .alert-message {
    display: flex;
    color: #2a3649;
    font-weight: 400;
    font-size: 1rem;
    padding: 1rem !important;
    align-items: center;
    gap: 1rem;

    flex-direction: column;
    text-align: center;
    @media (min-width: 576px) {
      flex-direction: row;
      text-align: left;
      gap: 1.5rem;
    }
    .pending-icon {
      color: #ffcd00;
      font-size: 2rem;
      min-width: 2rem;
    }
  }
  .alert-button-group {
    padding: 0;
  }
}

.vimeo-iframe-full {
  width: 100%;
  height: 100%;
}

.lg-signature-modal {
  @media (min-width: 1024px) {
    --width: 800px;
    --height: 680px;
  }
}
.app-toast{
  --background: #fff;
  --border-width: 2px;
  --border-style: solid;
  --border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;

  @media (min-width: 780px) {
    --max-width: 380px;
  }
  @media (min-width: 1400px) {
    --max-width: 400px;
  }
  @media (min-width: 1600px) {
    --max-width: 410px;
  }
}

.app-toast.large-version{
  @media (min-width: 780px) {
    --max-width: 600px !important;
  }
  @media (min-width: 1400px) {
    --max-width: 620px !important;
  }
  @media (min-width: 1600px) {
    --max-width: 640px !important;
  }
}

ion-toast.app-confirm-toast{
  --border-color:var(--confirm-status);
}
ion-toast.app-error-toast {
  --border-color: var(--error-status)
}
ion-toast.app-warning-toast {
  --border-color: var(--warning-status)
}
ion-toast.app-informative-toast {
  --border-color: var(--informative-status);
}

// toast message
ion-toast.app-toast::part(message) {
  line-height: 1.4rem;
}
ion-toast.app-informative-toast::part(message) {
  color: var(--informative-status);
}
ion-toast.app-confirm-toast::part(message),
ion-toast.app-warning-toast::part(message),
ion-toast.app-error-toast::part(message)  {
  color: var(--text-color);
}

// toast icon
ion-toast.app-toast::part(icon) {
  font-size: 1.7rem;
}
ion-toast.app-confirm-toast::part(icon) {
  color: var(--confirm-status);
}

ion-toast.app-error-toast::part(icon) {
  color: var(--error-status)
}

ion-toast.app-warning-toast::part(icon) {
  color: var(--warning-status)
}

ion-toast.app-informative-toast::part(icon) {
  color: var(--informative-status);
}

// close toast btn
ion-toast.app-confirm-toast::part(button), ion-toast.app-warning-toast::part(button) {
  color: #000000;
}
ion-toast.app-error-toast::part(button) {
  color: var(--error-status);
}
ion-toast.app-informative-toast::part(button) {
  color: var(--informative-status);
}


.toast-send-signature-successfully,
.toast-send-signature-error {
  --background: #fff;

  --border-width: 2px;
  --border-style: solid;
  --border-radius: 5px;

  font-weight: 600;
  font-size: 1rem;
  @media (min-width: 780px) {
    --max-width: 380px;
  }
  // @media (min-width: 1300px) {
  // 	transform: translateX(calc(50% - 18.6rem)) !important;
  // }
}

ion-toast.toast-send-signature-successfully::part(button) {
  color: #00e396;
}
ion-toast.toast-send-signature-error::part(button) {
  color: #ff4560;
}

.toast-send-signature-successfully {
  --border-color: #039855;
  color: #039855;
}
.toast-send-signature-error {
  --border-color: #ff4560;
  color: #ff4560;
}

ion-toast.toast-error-send-signature::part(header) {
  margin-bottom: 0rem !important;
  gap: 0.5rem;
}

.toast-error-send-signature {
  @media (min-width: 1024px) {
    --max-width: 30.438rem !important;
  }
}

ion-toast.toast-error-send-signature::part(button) {
  @media (min-width: 1024px) {
    margin: 0rem !important;
  }
}
.fs_13px {
  font-size: 13px;
}

.w-auto-slider-sesion {
  width: fit-content !important;
  @media (min-width: 1024px) {
    width: 31.4% !important;
    max-width: 31.4%;
    //   width: 16rem !important;
    //   max-width: 16rem ;
  }
  @media (min-width: 1240px) {
    width: 31.4% !important;
    max-width: 31.4%;
  }
  @media (min-width: 1440px) {
    width: 32% !important;
    max-width: 32%;
  }
  // @media (min-width: 1600px){
  //   width: 21.063rem !important; //337px
  //   max-width: 21.063rem !important; //337px
  // }
}

.next-live-course-btn {
  width: 85%;
  min-width: 85%;
}

.start-live-exam-btn {
  --padding-start: 1.5rem;
  --padding-end: 1.5rem;
  --padding-top: 1.15rem !important;
  --padding-bottom: 1.15rem !important;
}

.attend-status {
  color: #00e396;
}
.not-attend-status {
  color: #ff4242;
}
.late-status {
  color: #ffb700;
}

.modal-qr-guide {
  @media (min-width: 1200px) {
    width: 960px;
    // --width: 887px;
  }
}

.qr-web-modal {
  @media (min-width: 768px) {
    --height: 700px;
  }
  @media (min-width: 1024px) {
    --height: 710px;
  }
  @media (min-width: 1200px) {
    --width: 990px;
  }
}
.live-meeting-details-modals {
  @media (min-width: 768px) {
    --width: 600px;
    --height: 600px;
  }
}

.lg-signature-modal {
  @media (min-width: 1024px) {
    --width: 800px;
    --height: 680px;
  }
}

.h-fit {
  height: fit-content;
}
.w-fit {
  width: fit-content;
}

.custom-width-reminder-modal {
  @media (min-width: 768px) {
    width: 35.625rem; // 570px
  }
}



.offline-page {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1408px;
  padding: 1rem 1rem 1.75rem 1rem;

  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 5rem; //24px  80px
  }
  @media (min-width: 1280px) {
    padding: 2.5rem 2.5rem 5rem; //40px 80px
  }
  @media (min-width: 1600px) {
    padding: 2.5rem 0 5rem; //40px 80px
  }
}

.custom-page-bg {
  background-image: url("assets/img/custom-page-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.offline-page {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1408px;
  padding: 1rem 1rem 1.75rem 1rem;

  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 5rem; //24px  80px
  }
  @media (min-width: 1280px) {
    padding: 2.5rem 2.5rem 5rem; //40px 80px
  }
  @media (min-width: 1600px) {
    padding: 2.5rem 0 5rem; //40px 80px
  }
}

.custom-page-bg {
  background-image: url("assets/img/custom-page-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overflow_hidden {
  overflow: hidden;
}

.z-3 {
  z-index: 3;
}

.leading-18px {
  line-height: 1.125rem;
}

.text-no-wrap {
  text-wrap: nowrap;
}

ion-slides.guide-slide {
  --bullet-background: #555555;
}

.performance-custom-shadow {
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.12);
}
.performance-focused-shadow {
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.2);
}

.grayscale {
  filter: grayscale(1);
}

@keyframes bang {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

#confetti {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 9;
  i {
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    width: 6px;
    height: 16px;
    background: red;
    opacity: 0;
  }
}

.line-height-18px {
  line-height: 18px;
}

.clear-py-time {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


swiper-container.normalized {
  swiper-slide {
    line-height: 1;
  }
}

ion-datetime-button.ion-date-button::part(native) {
  width: 100%;
  background: none;
  text-align: left;
}
ion-datetime.ion-calendar {
  --background: #fff;
  --background-rgb: rgb(255, 255, 255);
}

ion-input.stacked-input .input-wrapper{
  gap: 14px;
  .label-text-wrapper{
    font-size: 20px;
  }
}

.no-scroll {
  overflow: hidden;
}

// body{
//   overflow: hidden;
// }

/* Checklist */

.basic-modal-type-sheet {
	--max-height: 50vh;
	padding-top: 100vh;
}
.color_primary {
	color: var(--primary);
}
.color_primary.disabled {
  color: #b6baff;
}
.bg_color_primary {
	background-color: var(--primary);
}
.text-capitalize {
  text-transform: capitalize;
}
.btn_disabled {
  --background: #D1D3D4;
  --border-color: #D1D3D4;
}
ion-modal.basic-modal.modal-pasantia::part(content) {
  overflow: initial !important;
}
.custom-input-time-pasantia .custom-time-picker button.relative {
  padding-bottom: 4px;
}
.border_error {
  border-color: #ff4560 !important;
}
.activity_content {
  img {
    margin: 15px auto;
    @media (min-width: 1024px) {
      max-width: 400px;
   }
  }
}

.download_results {
  --padding-bottom: 11px !important;
  --padding-top: 11px !important;
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  font-size: 22px;
  span {
  margin-right: 0;
  }
}

.box_search_themes .searchbar-input-container ion-icon.searchbar-search-icon {
  display: none !important;
}

.bg-custom-green-induction {
  background-color: #30B57A;
}

.activity-icon-container{
  @media (max-height: 700px ){
    height: auto;
    width: auto;
    padding: 0.5rem;

    #stepCardIcon{
      font-size: 1rem !important;
    }
  }

  @media (min-height: 700px ){
    height: 6rem;
    min-height: 6rem;
    width: 6rem;
    padding: 0;
  }
}

.activity-icon-container-sm{
  height: 50px;
  min-height: 50px;
  width: 50px;
  padding: 0;
}

.back-topic-ind-btn{
  min-width: 120px;
}

.signature-ind-btn{
  --padding-top:10px !important;
  --padding-bottom:10px !important;
}

input, textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}

ion-input.floating-input{
  .label-text-wrapper.sc-ion-input-md {
    -webkit-transform: translateY(80%) scale(1) !important;
    transform: translateY(80%) scale(1) !important;
  }
}

ion-input.floating-input.label-floating{
  .label-text-wrapper.sc-ion-input-md {
    -webkit-transform: translateY(-32%) scale(0.75) !important;
    transform: translateY(-32%) scale(0.75) !important;
  }
}
ion-input.floating-input{
  --highlight-color-valid: var(--confirm-status) !important;
}

.overflow-text-cover-3{
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.overflow-text-cover-2{
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.overflow-text-cover-1{
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.grecaptcha-badge {
  visibility: hidden !important;
  z-index: -1 !important;
}

ion-popover.checklist-status-tooltip{
  --max-width: 200px;
}
ion-popover.service-tooltip{
  --max-width: 220px;
}

ion-popover.checklist-status-tooltip::part(content), ion-popover.service-tooltip::part(content){
  border: 1px solid var(--primary);
}
ion-popover.checklist-status-tooltip::part(arrow), ion-popover.service-tooltip::part(arrow){
  overflow: visible;
}
ion-popover.checklist-status-tooltip::part(arrow)::after, ion-popover.service-tooltip::part(arrow)::after{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-color: var(--primary);
}

.img-container-home{
  img{
    max-height: 300px !important;
    object-fit: contain;
  }
}

.center-element-translate{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ion-modal.modal-video-player{
  // --height: fit-content;
  --width: fit-content;
  --height: 250px;
  --min-width: 92%;
  --max-width: 96%;
  --max-height: 250px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0);
  --background: transparent;
  @media (min-width: 768px ){
    --height: 480px;
    --max-height: 84%;
    --min-width: 90%;
    --max-width: 90%;
  }
  @media (min-width: 1024px ){
    --height: 500px;
    --max-height: 84%;
    --min-width: 80%;
    --max-width: 90%;
  }
  @media (min-width: 1440px ){
    --height: 580px;
    --max-height: 80%;
    --min-width: 84%;
   --max-width: 86%;
  }
  @media (min-width: 1540px ){
    --height: 640px;
    --min-width: 72%;
    --max-width: 80%;
  }
  @media (min-width: 1640px ){
    --height: 660px;
  }
  @media (min-width: 1800px ){
    --height: 700px;
  }
  @media (min-width: 2000px ){
    --height: 800px;
  }
}

swiper-container {
  --swiper-pagination-color: var(--ion-color-primary, #4200e9);
}

swiper-container.hide-pagination {
  --swiper-pagination-color: transparent;
  --swiper-pagination-bullet-inactive-color: transparent;
}

.center-transform{
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
}

.top-safe-area{
  margin-top: env(safe-area-inset-top);
}

.prevent-overflow-vimeo{
  @media (max-width: 1023px ){
    max-width: 100vw;
  }
}
ion-toolbar.benefits-toolbar{
  --ion-safe-area-top: 0;
}
.induc-card-desc{
  a {
    word-break: break-all !important;
  }
}

ion-toolbar.benefits-toolbar{
  --ion-safe-area-top: 0;
}

// ion-popover.side-nav-modules-options{
//   @media (min-width: 1024px ){
//     --min-width: 284px
//   }

// }

.side-nav-modules-opt{
  grid-template-columns: auto 1fr auto;
}
.checklist-popover  {
  --height: auto !important;
  --max-width: 200px !important;
  --width: 200px !important;
  padding: 0.3rem 0.5rem;
}

.error-global-btn{
  background-color: #ff4242 !important;
  &:hover {
    background-color: #f73434 !important;
  }

}

ion-modal.checklist-desc-modal {
  --height: 600px;
  @media (min-width: 1024px) {
    --height: 700px;
  }
}
// ion-popover.checklist-popover::part(content) {

// }

.checklist-popover  {
  --height: auto !important;
  --max-width: 200px !important;
  --width: 200px !important;
  padding: 0.3rem 0.5rem;
}

.error-global-btn{
  background-color: #ff4242 !important;
  &:hover {
    background-color: #f73434 !important;
  }

}

ion-modal.checklist-desc-modal {
  --height: 600px;
  @media (min-width: 1024px) {
    --height: 700px;
  }
}
// ion-popover.checklist-popover::part(content) {

// }

.gradient-menu {
  background: linear-gradient(0deg, var(--secondary) 9%, var(--primary) 65%);
}

ion-modal.zoom-img-modal {
  --width: 100%;
  --height: 100%;
  // --background: transparent;
  --background: #00000002;
  // min-width: 94%;
  // max-width: 94%;
  @media (min-width: 640px) {
    --width: 70%;
  }
  @media (min-width: 768px) {
    --width: 65%;
  }
  @media (min-width: 1024px) {
    --width: 52%;
  }
  @media (min-width: 1240px) {
    --width: 55%;
  }
  @media (min-width: 1400px) {
    --width: 58%;
  }
}

.act-image-zoom {
  margin: auto;
  height: auto;
  width: 100%;
  height: 100%;
  // min-width: 94%;
  // max-width: 94%;
  // @media (min-width: 640px) {
  //   min-width: 70%;
  //   max-width: 70%;
  // }
  // @media (min-width: 768px) {
  //   min-width: 65%;
  //   max-width: 65%;
  // }
  // @media (min-width: 1024px) {
  //   min-width: 52%;
  //   max-width: 52%;
  // }
  // @media (min-width: 1240px) {
  //   min-width: 45%;
  //   max-width: 45%;
  // }
}

.btn-pulse-primary {
  -webkit-animation: pulseAnimation 1.6s infinite;
  animation: pulseAnimation 1.6s infinite;
}

  .mdc-list-item__primary-text{
    color: #000000 !important;
  }
  .mat-pseudo-checkbox-minimal{
    display: none !important;
  }
  .mat-mdc-option{
   padding-bottom: 6px !important;
  }


@-webkit-keyframes pulseAnimation {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--primary);
    box-shadow: 0 0 0 0 var(--primary);
  }
  70% {
    -webkit-box-shadow: 0 0 0 .8em rgba(2, 0, 208, 0);
    box-shadow: 0 0 0 .8em rgba(2, 0, 208, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(2, 0, 208, 0);
    box-shadow: 0 0 0 0 rgba(2, 0, 208, 0);
  }
}

@keyframes pulseAnimation {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--primary);
    box-shadow: 0 0 0 0 var(--primary);
  }
  70% {
    -webkit-box-shadow: 0 0 0 .8em rgba(2, 0, 208, 0);
    box-shadow: 0 0 0 .8em rgba(2, 0, 208, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(2, 0, 208, 0);
    box-shadow: 0 0 0 0 rgba(2, 0, 208, 0);
  }
}

.force-modal-backdrop{
  --backdrop-opacity: 0.3 !important;
}

.new-report-form{
  ion-checkbox{
    --border-width:2px
  }

  ion-checkbox::part(label) {
    white-space: normal !important;
    overflow: visible !important;
    word-break: break-all;
    font-size: 14px;
    margin-inline: 8px 0px;

    @media (min-width: 1040px) {
      font-size: 16px;
    }
    // @media (min-width: 1440px) {
    //   font-size: 20px;
    // }
  }

  ion-toggle{
    --track-background-checked: var(--primary-light)
  }

  ion-toggle::part(label) {
    white-space: normal !important;
    overflow: visible !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    @media (min-width: 1040px) {
      font-size: 16px;
    }
    // @media (min-width: 1440px) {
    //   font-size: 20px;
    // }
  }
}
